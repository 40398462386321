import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import StoreBanner from '../components/store-banner'
import PriceComponent from '../components/price-component'
import PrimaryButton from '../components/primary-button'
import Stats5 from '../components/stats5'
import FeatureCard from '../components/feature-card'
import LinkIconButton2 from '../components/link-icon-button2'
import LinkIconButton1 from '../components/link-icon-button1'
import LinkIconButton3 from '../components/link-icon-button3'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container10">
    <Helmet>
    <title>PopCatRock | PopSwap</title>
    <meta property="og:title" content="PopCatRock | PopSwap" />
    <link rel="icon" type="image/png" href="/0xdce5726e3bc8e1f574416978279bb0ae62ab3c15-200h.png" />
    </Helmet>
      <div data-role="Header" className="home-header-container">
        <header className="home-header">
          <div className="home-container11"></div>
          <div className="home-container12">
            <div className="home-container13">
              <div className="home-container14">
                <img
                  alt="image"
                  src="/0xdce5726e3bc8e1f574416978279bb0ae62ab3c15-200h.png"
                  className="home-image10"
                />
              </div>
            </div>
            <div className="home-container15">
              <div className="home-container16">
                <div className="home-container17">
                  <div className="home-container18">
                    <h1 className="home-text10">
                      <span>PopCatRock</span>
                      <br></br>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home-container19">
            <StoreBanner></StoreBanner>
          </div>
        </header>
        <div data-role="MobileMenu" className="home-mobile-menu">
          <div className="home-top">
            <div className="home-logo">
              <img
                alt="image"
                src="/logotype-dark.svg"
                className="home-image11"
              />
            </div>
            <div data-role="CloseMobileMenu" className="home-close-menu">
              <svg viewBox="0 0 1024 1024" className="home-icon10">
                <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
              </svg>
            </div>
          </div>
          <div className="home-mid">
            <span className="home-text13">About</span>
            <span className="home-text14">Products</span>
            <span className="home-text15">Pricing</span>
            <span className="home-text16">Blog</span>
            <span className="home-text17">Jobs</span>
            <span className="home-text18">More</span>
          </div>
          <div className="home-bot">
            <div className="home-container20">
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link10"
              >
                <div className="home-container21">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon12"
                  >
                    <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                  </svg>
                </div>
              </a>
              <a
                href="https://dribbble.com"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link11"
              >
                <div className="home-container22">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon14"
                  >
                    <path d="M585.143 857.143c-5.714-33.143-27.429-147.429-80-284.571-0.571 0-1.714 0.571-2.286 0.571 0 0-222.286 77.714-294.286 234.286-3.429-2.857-8.571-6.286-8.571-6.286 65.143 53.143 148 85.714 238.857 85.714 52 0 101.143-10.857 146.286-29.714zM479.429 510.286c-9.143-21.143-19.429-42.286-30.286-63.429-193.143 57.714-378.286 53.143-384.571 53.143-0.571 4-0.571 8-0.571 12 0 96 36.571 184 96 250.286v0c102.286-182.286 304.571-247.429 304.571-247.429 5.143-1.714 10.286-2.857 14.857-4.571zM418.286 389.143c-65.143-115.429-134.286-209.143-139.429-216-104.571 49.143-182.286 145.714-206.857 261.714 9.714 0 166.286 1.714 346.286-45.714zM809.143 571.429c-8-2.286-112.571-35.429-233.714-16.571 49.143 135.429 69.143 245.714 73.143 268 84-56.571 143.429-146.857 160.571-251.429zM349.143 148c-0.571 0-0.571 0-1.143 0.571 0 0 0.571-0.571 1.143-0.571zM686.286 230.857c-65.714-58.286-152.571-93.714-247.429-93.714-30.286 0-60 4-88.571 10.857 5.714 7.429 76.571 100.571 140.571 218.286 141.143-52.571 194.286-133.714 195.429-135.429zM813.714 508c-1.143-88.571-32.571-170.286-85.143-234.286-1.143 1.143-61.143 88-209.143 148.571 8.571 17.714 17.143 36 25.143 54.286 2.857 6.286 5.143 13.143 8 19.429 129.143-16.571 256.571 11.429 261.143 12zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                </div>
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link12"
              >
                <div className="home-container23">
                  <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="home-icon16"
                  >
                    <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                  </svg>
                </div>
              </a>
              <a
                href="https://youtube.com"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link13"
              >
                <div className="home-container24">
                  <svg viewBox="0 0 1024 1024" className="home-icon18">
                    <path d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"></path>
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <PriceComponent rootClassName="price-componentroot-class-name"></PriceComponent>
      <span className="home-text19">
        <span>CA:0xdce5726e3bc8e1f574416978279bb0ae62ab3c15</span>
        <br></br>
      </span>
      <div className="home-main">
        <div className="home-blur-background"></div>
        <div className="home-hero">
          <div className="home-container25">
            <h1 className="home-text22">
              <span>
                Welcome to
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>PopCatRock</span>
              <br></br>
            </h1>
            <span className="home-text27">
              Trade, earn, and own crypto on the best DEX on Bitrock
            </span>
            <a
              href="https://popswap.click"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link14"
            >
              <PrimaryButton
                button="Open App"
                className="home-component12"
              ></PrimaryButton>
            </a>
          </div>
          <img alt="image" src="/heropic-500h.png" className="home-image12" />
        </div>
        <img
          alt="image"
          src="/turquoise-circle.svg"
          className="home-turquoise-cirble"
        />
        <img
          alt="image"
          src="/purple-circle.svg"
          className="home-purple-circle"
        />
        <img alt="image" src="/left.svg" className="home-left" />
        <img alt="image" src="/right.svg" className="home-right" />
      </div>
      <div className="home-clients">
        <div className="home-divider1"></div>
        <a
          href="https://www.dextools.io/app/en/bitrock/pair-explorer/0x7c544bdba052b0c90dd14d55b3251b9f3d4c26cc?t=1724222354399"
          target="_blank"
          rel="noreferrer noopener"
          className="home-link15"
        >
          <img alt="image" src="/dextools-200h.png" className="home-image13" />
        </a>
        <a
          href="https://www.geckoterminal.com/bitrock/pools/0x7c544bdba052b0c90dd14d55b3251b9f3d4c26cc"
          target="_blank"
          rel="noreferrer noopener"
          className="home-link16"
        >
          <img alt="image" src="/download-200h.png" className="home-image14" />
        </a>
        <a
          href="https://www.dexview.com/bitrock/0xdcE5726e3Bc8E1F574416978279bb0AE62AB3c15"
          target="_blank"
          rel="noreferrer noopener"
          className="home-link17"
        >
          <img alt="image" src="/dexview-200h.png" className="home-image15" />
        </a>
        <div className="home-divider2"></div>
      </div>
      <Stats5
        stat1={
          <Fragment>
            <span className="home-text28 thq-heading-2">200k</span>
          </Fragment>
        }
        stat2={
          <Fragment>
            <span className="home-text29 thq-heading-2">9/9</span>
          </Fragment>
        }
        stat1Description={
          <Fragment>
            <span className="home-text30 thq-body-small">Max Supply</span>
          </Fragment>
        }
        stat2Description={
          <Fragment>
            <span className="home-text31 thq-body-small">Tax</span>
          </Fragment>
        }
      ></Stats5>
      <div className="home-features1">
        <h2 className="Headline2 home-text32">PopSwap</h2>
        <span className="home-text33">
          Experience the seamless and intuitive PopSwap interface, where you can
          effortlessly create and manage liquidity pools (LP) and swap tokens.
          Enjoy the convenience of swapping tokens directly to a different
          address, allowing you to purchase tokens with a max wallet limit
          across multiple wallets without switching accounts.
        </span>
        <div className="home-features2">
          <div className="home-container26">
            <FeatureCard
              text="Trade crypto instantly across multiple chains"
              cardTitle="Swap"
            ></FeatureCard>
            <a
              href="https://popswap.click/#/swap?outputCurrency=0xdce5726e3bc8e1f574416978279bb0ae62ab3c15"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link18"
            >
              <LinkIconButton2
                text="Swap Now"
                className="home-component15"
              ></LinkIconButton2>
            </a>
          </div>
          <div className="home-container27">
            <FeatureCard
              text="Fund liquidity pools, earn trading fees"
              imageSrc="/02.svg"
              cardTitle="Liquidity"
            ></FeatureCard>
            <a
              href="https://popswap.click/#/add/BROCK/0xdcE5726e3Bc8E1F574416978279bb0AE62AB3c15"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link19"
            >
              <LinkIconButton1
                text="Start Earning"
                className="home-component17"
              ></LinkIconButton1>
            </a>
          </div>
          <div className="home-container28">
            <FeatureCard
              text="User Friendly interface made to be easy to use"
              imageSrc="/03.svg"
              cardTitle="User friendly"
            ></FeatureCard>
            <a
              href="https://popswap.click/#/swap"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link20"
            >
              <LinkIconButton3
                text="Check it out"
                className="home-component19"
              ></LinkIconButton3>
            </a>
          </div>
        </div>
      </div>
      <div className="home-container29">
        <div className="home-testimonials">
          <div className="home-container30">
            <div className="home-container31">
              <img alt="image" src="/quote-mark.svg" className="home-image16" />
              <h1 className="home-text34">
                <span className="Headline2 home-text35">
                  How to buy $POPCAT
                </span>
                <br></br>
              </h1>
              <span>
                <span>
                  $POPCAT token is available on the Bitrock Chain (BROCK).
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>
                  Metamask is the market leader when it comes to wallets.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>On Google Chrome, visit metamask.io</span>
                <br></br>
                <span>to download the extension and set up a wallet.</span>
                <br></br>
                <span>
                  {' '}
                  On mobile? Get MetaMask&apos;s app for iPhone or Android.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
              </span>
            </div>
            <div className="home-container32">
              <div className="home-container33"></div>
            </div>
          </div>
          <div className="home-container34">
            <div className="home-container35">
              <span className="Subtitle1">Send BROCK to Metamask</span>
              <div className="home-container36">
                <img
                  alt="image"
                  src="/quote-mark.svg"
                  className="home-image17"
                />
                <div className="home-container37">
                  <span className="home-text52">
                    Bridge your BROCK from mainnet to Bitrock or transfer it to
                    your MetaMask wallet address from another wallet (e.g.
                    Bitmart).
                  </span>
                </div>
              </div>
            </div>
            <div className="home-container38">
              <div className="home-container39">
                <div className="home-container40">
                  <div className="home-container41">
                    <div className="home-container42">
                      <span className="Subtitle1">Visit a DEX</span>
                      <div className="home-container43">
                        <img
                          alt="image"
                          src="/quote-mark.svg"
                          className="home-image18"
                        />
                        <div className="home-container44">
                          <span className="home-text54">
                            <span>
                              You can currently buy $POPCAT on
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: ' ',
                                }}
                              />
                            </span>
                            <a
                              href="https://swap.rock-swap.io/#/swap?outputCurrency=0xdce5726e3bc8e1f574416978279bb0ae62ab3c15"
                              target="_blank"
                              rel="noreferrer noopener"
                              className="home-link21"
                            >
                              RockSwap
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: ' ',
                                }}
                              />
                            </a>
                            <span>
                              and
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: ' ',
                                }}
                              />
                            </span>
                            <a
                              href="https://popswap.click/#/swap?outputCurrency=0xdce5726e3bc8e1f574416978279bb0ae62ab3c15"
                              target="_blank"
                              rel="noreferrer noopener"
                              className="home-link22"
                            >
                              PopSwap
                            </a>
                            <span>.</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home-container45">
            <span className="Subtitle1">Swap your BROCK for POPCAT</span>
            <div className="home-container46">
              <img alt="image" src="/quote-mark.svg" className="home-image19" />
              <div className="home-container47">
                <span className="home-text59">
                  Enter the amount of $BROCK you would like to swap for $POPCAT.
                  Click Connect Wallet then Swap.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-feature1">
        <h3 className="Headline3">Or you can buy it here</h3>
        <div className="home-container48">
          <iframe
            src="https://popswap.click/#/swap?outputCurrency=0xdce5726e3bc8e1f574416978279bb0ae62ab3c15"
            className="home-iframe"
          ></iframe>
        </div>
      </div>
      <div className="home-container49">
        <h3 className="Headline3">Dev Note:</h3>
        <span className="home-text62">
          <span>
            Hi, I’m PopCatDev, the creator and developer of PopCat. I’m a
            20-year-old who built this project to change people’s minds about
            memecoins. I believe in the power of community and innovation, and I
            want to inspire others to pursue their own crypto dreams.
          </span>
          <br className="Lead1"></br>
          <br className="Lead1"></br>
          <span>
            For now, I prefer to keep my identity private due to government
            restrictions in my country. However, I plan to reveal my identity
            soon and make everything public once we have established PopCat as a
            legitimate business.
          </span>
          <br className="Lead1"></br>
          <br className="Lead1"></br>
          <span>
            If a 20-year-old like me can build all this, why can’t you?
            Let&apos;s change the crypto world together!
          </span>
        </span>
      </div>
      <div className="home-feature2">
        <div className="home-container50">
          <h3 className="home-text70">
            <span className="Headline2 home-text71">
              effortlessly trade assets on the Bitrock chain using PopSwap 
            </span>
            <br></br>
            <span>More features coming soon</span>
            <br></br>
          </h3>
        </div>
        <img
          alt="image"
          src="/popswap-1200w.png"
          image_src="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
          className="home-image20"
        />
      </div>
      <div className="home-cta">
        <div className="home-container51">
          <div className="home-container52">
            <h2 className="home-text75">
              <span className="home-text76">
                Where Memes Meet Value Trade, earn and Thrive in the Ultimate
                Bitrock Dex!
              </span>
              <br></br>
            </h2>
            <span>
              <span>**</span>
              <span className="home-text80">Disclaimer</span>
              <span>
                :** POPCAT is not an investment; it is a community-focused
                project dedicated to developing blockchain solutions. If you
                choose to invest in POPCAT, it is entirely your decision. At no
                point will we promote this project as an investment or guarantee
                any financial gains. POPCAT is simply a DeFi application created
                by the Bitrock community, with a mission to add more utilityto
                blockchain technology.
              </span>
            </span>
            <span className="home-text82 Subtitle2">Trade Now</span>
            <StoreBanner></StoreBanner>
            <a
              href="https://https:popswap.click"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link23 button"
            >
              Open App
            </a>
          </div>
          <div className="home-container53">
            <img
              alt="image"
              src="/0xdce5726e3bc8e1f574416978279bb0ae62ab3c15-200h.png"
              image_src="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
              className="home-image21"
            />
            <img
              alt="image"
              src="/0xa92214fa4e511620a66945a17363f40b6f69f320-300w.png"
              image_src="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
              className="home-image22"
            />
          </div>
        </div>
      </div>
      <div className="home-footer">
        <footer className="home-container54">
          <Link to="/" className="home-navlink">
            <img
              alt="image"
              src="/0xdce5726e3bc8e1f574416978279bb0ae62ab3c15-200h.png"
              className="home-image23"
            />
          </Link>
          <div className="home-container55">
            <a
              href="https://popswap.click/#/swap?outputCurrency=0xdce5726e3bc8e1f574416978279bb0ae62ab3c15"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link24"
            >
              Buy on PopSwap
            </a>
            <a
              href="https://swap.rock-swap.io/#/swap?outputCurrency=0xdce5726e3bc8e1f574416978279bb0ae62ab3c15"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link25"
            >
              Buy on RockSwap
            </a>
            <a
              href="https://docs.popswap.click/"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link26"
            >
              Docs
            </a>
          </div>
          <div className="home-divider3"></div>
          <div className="home-container56">
            <span className="home-text83 Body2">
              © 2024 PopCatRock &amp; PopSwap. All rights reserved
            </span>
            <div className="home-container57">
              <a
                href="https://t.me/popcatrock"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link27"
              >
                <svg viewBox="0 0 1024 1024" className="home-icon20">
                  <path d="M512 0c-282.8 0-512 229.2-512 512s229.2 512 512 512 512-229.2 512-512-229.2-512-512-512zM763.6 351l-84 395.8c-5.8 28.2-22.8 34.8-46.4 21.8l-128-94.6-61.4 59.8c-7.2 7-12.8 12.8-25.6 12.8-16.6 0-13.8-6.2-19.4-22l-43.6-143.2-126.6-39.4c-27.4-8.4-27.6-27.2 6.2-40.6l493.2-190.4c22.4-10.2 44.2 5.4 35.6 40z"></path>
                </svg>
              </a>
              <a
                href="https://twitter.com/popcatrock"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link28"
              >
                <svg viewBox="0 0 1024 1024" className="home-icon22">
                  <path d="M888.875 224.512c-7.936 10.325-16.683 20.267-26.283 29.696-9.941 9.899-14.805 24.192-11.861 38.741 1.579 7.765 2.56 17.237 2.603 27.051 0 224.256-103.637 382.763-247.893 464.981-112.939 64.384-253.355 83.413-393.472 47.787 53.12-15.403 104.96-39.296 153.301-72.107 6.272-4.224 11.648-10.368 15.019-17.963 9.557-21.547-0.128-46.763-21.675-56.32-118.357-52.608-176.213-125.056-203.477-195.968-17.792-46.251-23.296-93.781-22.187-137.685 0.896-34.603 5.888-66.56 11.861-93.099 16.853 14.592 34.304 30.080 52.736 44.245 87.509 67.285 197.291 105.813 315.563 102.741 23.083-0.64 41.557-19.499 41.557-42.667v-43.136c-0.085-7.637 0.384-15.232 1.451-22.784 4.821-34.389 21.504-66.944 49.579-91.349 30.933-26.923 69.803-38.741 107.776-36.096s74.795 19.755 101.717 50.688c10.752 12.203 27.691 17.749 44.075 12.971 9.856-2.859 19.755-6.101 29.653-9.728zM956.757 93.141c-31.573 22.272-64.981 39.509-97.579 51.413-39.723-35.669-89.216-55.552-139.776-59.093-59.648-4.139-121.003 14.464-169.685 56.832-44.203 38.443-70.485 89.899-78.080 143.872-1.579 11.307-2.347 22.741-2.304 34.133-82.005-6.059-157.611-36.267-219.776-84.096-33.067-25.429-62.336-55.851-86.784-90.24-13.653-19.2-40.277-23.765-59.477-10.112-6.571 4.651-11.392 10.795-14.293 17.451 0 0-5.632 12.757-12.16 32.427-4.779 14.379-10.283 33.067-15.232 55.040-6.955 30.805-12.885 68.352-13.909 109.696-1.323 52.352 5.12 111.445 27.819 170.496 29.141 75.733 83.883 148.395 176.939 205.781-66.944 30.976-138.453 44.331-207.915 41.259-23.552-1.024-43.477 17.195-44.501 40.747-0.725 16.597 8.107 31.403 21.888 39.168 209.28 116.267 444.843 114.261 625.749 11.136 172.416-98.176 290.987-285.867 290.987-539.051-0.043-7.125-0.341-14.080-0.981-20.864 42.923-47.573 71.509-103.637 85.163-161.323 5.419-22.912-8.789-45.909-31.701-51.328-12.373-2.944-24.747-0.128-34.432 6.656z"></path>
                </svg>
              </a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Home
