import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './stats5.css'

const Stats5 = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="stats5-max-width thq-section-max-width">
        <div className="stats5-container2"></div>
        <div className="stats5-container3">
          <div className="stats5-container4">
            <h2>
              {props.stat1 ?? (
                <Fragment>
                  <h2 className="stats5-text7 thq-heading-2">
                    Total Liquidity Pools
                  </h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.stat1Description ?? (
                <Fragment>
                  <span className="stats5-text6 thq-body-small">
                    Number of liquidity pools created on PopSwap
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="stats5-container5">
            <h2>
              {props.stat2 ?? (
                <Fragment>
                  <h2 className="stats5-text5 thq-heading-2">Total Swaps</h2>
                </Fragment>
              )}
            </h2>
            <span>
              {props.stat2Description ?? (
                <Fragment>
                  <span className="stats5-text8 thq-body-small">
                    Number of token swaps executed on the platform
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

Stats5.defaultProps = {
  stat2: undefined,
  stat1Description: undefined,
  stat1: undefined,
  stat2Description: undefined,
}

Stats5.propTypes = {
  stat2: PropTypes.element,
  stat1Description: PropTypes.element,
  stat1: PropTypes.element,
  stat2Description: PropTypes.element,
}

export default Stats5
