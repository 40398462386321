import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StatsCard from './stats-card';
import './price-component.css';

const PriceComponent = ({ rootClassName }) => {
  // State to hold the price and market cap
  const [price, setPrice] = useState(null);
  const [marketCap, setMarketCap] = useState(null);

  // Fetch the data from the API when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://api.geckoterminal.com/api/v2/networks/bitrock/tokens/0xdcE5726e3Bc8E1F574416978279bb0AE62AB3c15'
        );
        const data = await response.json();
        const tokenData = data.data.attributes;
        
        setPrice(`$${parseFloat(tokenData.price_usd).toFixed(4)}`);
        setMarketCap(`$${parseFloat(tokenData.fdv_usd).toFixed(2)}`);
      } catch (error) {
        console.error('Error fetching token data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={`price-component-container ${rootClassName}`}>
      <StatsCard number={price || '$0.0000'} description="Price" />
      <StatsCard
        number={marketCap || '$0.00'}
        imageSrc="/07.svg"
        description="Market Cap"
      />
    </div>
  );
};

PriceComponent.defaultProps = {
  rootClassName: '',
};

PriceComponent.propTypes = {
  rootClassName: PropTypes.string,
};

export default PriceComponent;
